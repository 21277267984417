<script setup>
const emits = defineEmits(['enterView', 'leftView']);
const refTrigger = ref();

const observer = process.client ? new window.IntersectionObserver(([entry]) => {
  if(entry.isIntersecting) {
    emits('enterView');
    return
  }
  emits("leftView")
}, {root: null, threshold: 0.1}) : null;

const initObserver = () => {
  const el = refTrigger.value;
  observer.observe(el);
}

const disconnectObserver = () => {
  observer.disconnect();
}

onMounted(() => {
  initObserver();
})

onUnmounted(() => {
  disconnectObserver();
})

</script>

<template>
  <div ref="refTrigger">
    <slot />
  </div>
</template>
